<template>
  <div class="wa__modeling__offer">
    <v-container class="py-1 d-none d-md-block">
      <div>
        <SocialIcon />
      </div>
    </v-container>
    <Header />
    <main>
      <div class="social__divider__wrapper">
        <SocialDivider :title="$_t('title')" :sub-title="$_t('subTitle')" />
      </div>
      <v-container>
        <list class="wa__large" style="min-height: 600px">
          <div>
            <div class="pending__box">
              <div class="d-flex justify-center mb-7">
                <div class="pending">
                  <i class="WMi-heart-linear"></i>
                </div>
              </div>
              <div class="pl-0 pl-md-15 w-75 mx-auto">
                <div class="title" v-text="$_t('offerTitle')"></div>
                <div class="offer__response mt-2" v-text="$_t('offerResponse')"></div>
                <div
                  class="offer__response--text mt-7 pl-2"
                  v-text="$_t('offertext')"
                ></div>
                <div class="text-left mt-7">
                  <a :href="localeUrl($baseUrl)" class="text-decoration-none">
                    <block-button
                      :text="$_t('returnDashboard')"
                      height="30"
                      text-mode="text"
                      icon="WMi-left-chevron"
                      class="btn-back pl-0"
                    ></block-button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </list>
      </v-container>
    </main>
  </div>
</template>
<script>
import SocialDivider from "../../components/Global/Section/SocialDivider.vue";
import SocialIcon from "../../components/Global/Header/User/SocialIcon.vue";
import Header from "../../components/Global/Header/User/Header.vue";
export default {
  components: {
    SocialDivider,
    SocialIcon,
    Header,
  },
};
</script>
<style scoped>
.wa__modeling__offer {
  background-color: #f5f3f3 !important;
}
.social__divider__wrapper {
  width: 40%;
  margin: 30px auto 0 auto;
}
.pending__box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.pending {
  width: 200px;
  height: 200px;
  background-color: #f1f1f183;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pending i {
  font-size: 90px;
}
.title {
  color: var(--color-black);
  font-size: 27px !important;
  font-family: "Montserrat-regular" !important;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 1px;
}
.offer__response {
  font-family: "Montserrat-light" !important;
  font-size: 24px !important;
}
.offer__response--text {
  font-family: "Montserrat-light" !important;
  font-size: 18px !important;
}
.text {
  font-family: "Montserrat-regular" !important;
}
ul {
  background-color: var(--color-bg-gray);
  padding: 7px 40px;
  width: 100%;
  border-radius: 4px;
}
ul li {
  width: 50%;
  font-family: "Montserrat-regular";
  font-size: 10px;
  text-align: left;
  letter-spacing: 1px;
}
ul li:last-child {
  border-left: 1px solid var(--color-gray);
  text-align: right;
}
.btn-back {
  font-size: 14px;
  font-family: "Montserrat-light";
  letter-spacing: 2px;
}
@media screen and (max-width: 960px) {
  .pending__box {
    position: sticky;
    top: 0;
    left: 0;
    transform: none;
    margin: 100px 0;
  }
}
</style>
